import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import loadable from '@loadable/component';

import theme from './global/theme';

import './global/sig';

const templates = {
  blogLanding: loadable(() => import('./templates/BlogLanding')),
  buttonTest: loadable(() => import('./templates/ButtonTest')),
  careers: loadable(() => import('./templates/Careers')),
  catering: loadable(() => import('./templates/Catering')),
  cateringQuote: loadable(() => import('./templates/CateringQuote')),
  contact: loadable(() => import('./templates/Contact')),
  contactForm: loadable(() => import('./franchising/templates/ContactForm')),
  error: loadable(() => import('./Error')),
  f0f: loadable(() => import('./F0F')),
  franchisee: loadable(() => import('./templates/Franchisee')),
  giftCard: loadable(() => import('./templates/GiftCard')),
  home: loadable(() => import('./templates/Home')),
  ingredientInfo: loadable(() => import('./templates/IngredientInfo')),
  insideLook: loadable(() => import('./franchising/templates/InsideLook')),
  investment: loadable(() => import('./franchising/templates/Investment')),
  legal: loadable(() => import('./templates/Legal')),
  menu: loadable(() => import('./templates/Menu')),
  newsroom: loadable(() => import('./templates/Newsroom')),
  nutritionBuilderLanding: loadable(() =>
    import('./templates/NutritionBuilderLanding')
  ),
  ourStory: loadable(() => import('./templates/OurStory')),
  overview: loadable(() => import('./franchising/templates/Overview')),
  post: loadable(() => import('./templates/BlogPost')),
  rewards: loadable(() => import('./templates/Rewards')),
  siteMap: loadable(() => import('./templates/SiteMap'))
};

const App = ({ page, status, ...props }) => {
  const templateExists = page.template in templates;

  const PageTemplate =
    status === 200 && templateExists
      ? templates[page.template]
      : status === 404
        ? templates.f0f
        : templates.error;

  return (
    <ThemeProvider theme={theme}>
      <PageTemplate {...page} {...props} status={status} />
    </ThemeProvider>
  );
};

export const pageResponse = {
  acf: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  id: PropTypes.number.isRequired,
  media: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  permalink: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  yoast: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

App.propTypes = {
  footerNavigation: PropTypes.arrayOf(PropTypes.object),
  headerNavigation: PropTypes.arrayOf(PropTypes.object).isRequired,
  legalNavigation: PropTypes.arrayOf(PropTypes.object),
  orderNavigation: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.object.isRequired,
  siteMapNavigation: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.number.isRequired
};

export default App;
